<template>
  <v-dialog v-model="dialog" max-width="900" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="server.connection"
          >Variáveis: {{ server.connection.database !== null ? server.connection.database.toUpperCase() : '-' }}</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="addVariableField = true">
            Adicionar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="server.configuration">
        <div v-for="(value, key) in server.configuration.variables" :key="key">
          <v-row no-gutters class="px-6">
            <v-col cols="5" class="my-2">
              <v-text-field hide-details class="mr-4" disabled label="Variável" :value="key" required outlined />
            </v-col>
            <v-col cols="7" class="my-2">
              <v-text-field hide-details label="Valor" :value="value" required outlined>
                <template v-slot:append-outer>
                  <v-icon color="error" @click="deleteManager(key)">mdi-close-box</v-icon>
                </template>
              </v-text-field>
              <change-dialog :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeVariable(key)" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>

    <!-- add variable modal -->
    <v-dialog v-if="server.configuration" v-model="addVariableField" width="640">
      <v-card class="pa-2">
        <v-card-title class="px-4"><v-icon class="mr-2">mdi-console-line</v-icon> Adicionar Variável:</v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="5">
                <v-text-field v-model="variable" class="mr-2" :rules="variableRules" label="Variável" outlined />
              </v-col>
              <v-col cols="7">
                <v-text-field v-model="value" :rules="valueRules" label="Valor" outlined />
              </v-col>
            </v-row>
            <v-divider />
            <btn-save-cancel :btn-outline="[true, false]" :btn-size="225" props-class="pa-0 mt-4" @onSave="saveManager" @onCancel="closeAndClean" />
            <change-dialog :dialog.sync="changesDialogInsert" :message.sync="changesMessage" @proceed="addVariable" />
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_REMOVE_VARIABLE, MUTATE_ADD_VARIABLE } from '@/modules/accounts/graphql/index'

export default {
  components: {
    BtnSaveCancel: () => import('@/components/base/BtnSaveCancel'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    server: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    addVariableField: false,
    variable: '',
    value: '',
    valid: false,
    variableRules: [v => !!v || 'Nome de variável é obrigatório'],
    valueRules: [v => !!v || 'Valor da variável é obrigatório'],
    changesDialogInsert: false,
    changesDialogDelete: false,
    changesMessage: ''
  }),
  methods: {
    async removeVariable(key) {
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_VARIABLE,
          variables: {
            id: this.server.accountId,
            index: this.server.index,
            key: key.toString()
          }
        })

        delete this.server.configuration.variables[key]
        this.$emit('update:server', this.server)
        this.$snackbar({ message: `Variável "${key}" removida`, snackbarColor: '#2E7D32' })
        this.closeDialog()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover variável', snackbarColor: '#F44336' })
      }
    },
    async addVariable() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Favor validar os campos', snackbarColor: '#F44336' })
      const key = this.variable.toString()
      const value = this.value.toString()
      this.$setLogMessage(this.changesMessage)

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_VARIABLE,
          variables: {
            id: this.server.accountId,
            server_index: this.server.index,
            key,
            value
          }
        })
        this.server.configuration.variables[key] = value
        this.addVariableField = false
        this.closeAndClean()
        this.closeDialog()
        this.$snackbar({ message: 'Variável adicionada', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao adicionar variável', snackbarColor: '#F44336' })
      }
    },
    closeAndClean() {
      this.addVariableField = false
      this.variable = ''
      this.value = ''
    },
    closeDialog() {
      return this.$emit('close')
    },
    saveManager() {
      if (this.isDeploying) return this.addVariable()
      this.changesDialogInsert = true
    },
    deleteManager(key) {
      if (this.isDeploying) return this.removeVariable(key)
      this.changesDialogDelete = true
    }
  }
}
</script>
